import React, { useRef, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import isMobile from "react-device-detect"

import sty from "./FullScreenVideoBgWrapper.module.scss";

const FullScreenVideoBgWrapper = ({
  children,
  youTubeVideoId,
  videoSourceUrl,
}) => {
  const refVideoContainer = useRef(null);

  if (youTubeVideoId) {
    window.onYouTubeIframeAPIReady = function() {
      const player = new window.YT.Player(refVideoContainer.current, {
        width: "100%",
        videoId: youTubeVideoId,
        playerVars: { autoplay: 1, playsinline: 1 },
        events: {
          onReady: (e) => {
            // e.target.mute();
            e.target.playVideo();
          },
          onStateChange: function(e) {
            if (e.data === window.YT.PlayerState.ENDED) {
              player.playVideo();
              player.unMute();
              // isUnMuted = true;
            }
          },
        },
      });
    };
  }


  useEffect(() => {

    if ( videoSourceUrl ) {
      if ( !isMobile ) {
        var bg_video = document.querySelector('.bg_video');
        bg_video.muted = !bg_video.muted;
      }
    }

    return () => {};
  }, []);

  return (
    <div className={sty.FullScreenVideoBgWrapper}>
      <div className={sty.box__bg}>
        <div className={sty.box__bg_inner}>
          {youTubeVideoId && (
            <>
              <Helmet>
                <script src="https://www.youtube.com/iframe_api"></script>
              </Helmet>
              <div ref={refVideoContainer}></div>
            </>
          )}
          {videoSourceUrl && (
            <video
              className="bg_video"
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              data-vscid="e12oj59gj"
            >
              <source src={videoSourceUrl} type="video/mp4" />
            </video>
          )}
        </div>
      </div>

      {children}
    </div>
  );
};

FullScreenVideoBgWrapper.propTypes = {
  youTubeVideoId: PropTypes.string,
  videoSourceUrl: PropTypes.string,
};

export default FullScreenVideoBgWrapper;
