import React, { useRef, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Transition } from "react-transition-group";
import cx from "classnames";

import { gsap, Expo, TimelineMax, Linear } from "gsap";
import sty from "./Campfire.module.scss";

import InnerHeight from 'ios-inner-height'
import AudioPreloadList from "../../../AudioPreloadList";
import FullScreenVideoBgWrapper from "../../../FullScreenVideoBgWrapper";
import ModalPoetry from "./components/ModalPoetry";
import window from 'global'

import animateStartOffset from "./animations/animateStartOffset.js";

// import ModalBlotter from "./components/ModalBlotter";
// import SmokyText from "./SmokyText";
import originData from "./data.js";

const data = originData.map((data, index) => {
  data.indexNum = index;
  return data;
});

const SVGText = React.memo(
  ({
    index,
    name,
    indexNum,
    xlinkHref,
    className,
    handleMouseInName,
    handleMouseOutName,
    isHidden,
  }) => {
    return (
      <text className="flow_path_wrap" width="100%">
        <textPath
          className={cx("path_text_inner", className, { hidden: isHidden })}
          alignmentBaseline="top"
          startOffset={`${index * 250}px`}
          data-index={indexNum}
          xlinkHref={xlinkHref}
          onMouseEnter={handleMouseInName}
          onMouseLeave={handleMouseOutName}
          name={name}
        >
          {name}
        </textPath>
      </text>
    );
  }
);

const Campfire = ({}) => {
  const refTweenList = useRef([]);

  // const [isMute, setIsMute] = useState(false);
  const [indexOfData, setIndexOfData] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleMouseInName = (e) => {
    try {
      setIndexOfData(e.target.dataset.index);
      setIsModalOpen(true);
      refTweenList.current.forEach((tween) => tween.timeScale(0.1));
    } catch (error) {
      console.log(error);
    }
  };

  const handleMouseOutName = () => {
    setIsModalOpen(false);
    refTweenList.current.forEach((tween) => {
      tween.timeScale(1);
    });
  };

  useEffect(() => {
    refTweenList.current = animateStartOffset();

    var home_logo = document.querySelector('.home_logo');
    var cursor = document.querySelector('.cursor');

    var bg_video = document.querySelector('.bg_video');
    var flow_wrap = document.querySelector('.flow_wrap');
    var poem_content = document.querySelector('.poem_content');

    
    // home_logo.classList.add('video_playing');
    home_logo.addEventListener('click', function(){
      home_logo.classList.remove('video_playing');
      cursor.classList.remove('campfire_playing');
      document.body.classList.remove('campfire_playing');
    });
    
    flow_wrap.style.height = window.innerHeight + 'px';
    poem_content.style.height = window.innerHeight + 'px';
    
    gsap.fromTo(bg_video, {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 3,
      ease: Expo.easeOut
    });
    
    gsap.fromTo(flow_wrap, {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 3,
      delay: 1,
      ease: Expo.easeOut,
      onComplete: function(){
        setTimeout( function(){
          cursor.classList.add('campfire_playing');
          flow_wrap.style.pointerEvents = 'all';
        }, 100);
      }
    });

    // var bg_video = document.querySelector('.bg_video');
    // bg_video.play();

    return () => {};
  }, []);

  return (
    <FullScreenVideoBgWrapper
      // youTubeVideoId="-DDuWPSKbfw" //fAfRcTQpk9U
      videoSourceUrl={require("../../../../images/campfire.mp4")}
    >
      <ModalPoetry
        isModalOpen={isModalOpen}
        text={originData[indexOfData].text}
      />

      <div className={sty.Campfire}>
        <div className={sty.box__sentences}></div>
        <div className="flow_wrap">
          <div className="flow_path">
            <svg
              className="flow_path_svg"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="2560px"
              height="1440px"
              viewBox="0 0 2560 1440"
            >
              {[...data.slice(0, 8), ...data.slice(0, 8)].map(
                ({ name, indexNum }, index) => {
                  return (
                    <SVGText
                      index={index}
                      className="path_text_inner_A "
                      key={`${name}${index}A`}
                      indexNum={indexNum}
                      xlinkHref="#flow_path_A"
                      handleMouseInName={handleMouseInName}
                      handleMouseOutName={handleMouseOutName}
                      name={name}
                      isHidden={isModalOpen && indexOfData != indexNum}
                    />
                  );
                }
              )}
              {[...data.slice(8, 16), ...data.slice(8, 16)].map(
                ({ name, indexNum }, index) => {
                  return (
                    <SVGText
                      index={index}
                      className="path_text_inner_B"
                      key={`${name}${index}B`}
                      indexNum={indexNum}
                      xlinkHref="#flow_path_B"
                      handleMouseInName={handleMouseInName}
                      handleMouseOutName={handleMouseOutName}
                      name={name}
                      isHidden={isModalOpen && indexOfData != indexNum}
                    />
                  );
                }
              )}
              {[...data.slice(16, 24), ...data.slice(16, 24)].map(
                ({ name, indexNum }, index) => {
                  return (
                    <SVGText
                      index={index}
                      className="path_text_inner_C"
                      key={`${name}${index}C`}
                      indexNum={indexNum}
                      xlinkHref="#flow_path_C"
                      handleMouseInName={handleMouseInName}
                      handleMouseOutName={handleMouseOutName}
                      name={name}
                      isHidden={isModalOpen && indexOfData != indexNum}
                    />
                  );
                }
              )}

              <path
                id="flow_path_A"
                className="flow_main_path"
                fill="transparent"
                d="M0.67,378.93c1180.04,420.23,1613.19-235.7,3022.85,53.42c0,0,846.63,257.16,1734.23,10.26
                c929.76-258.62,1961.31,53.82,1961.31,53.82"
              />
              <path
                id="flow_path_B"
                className="flow_main_path"
                fill="transparent"
                d="M0.67,677.16c1180.04,420.23,1613.19-235.7,3022.85,53.42c0,0,846.63,257.16,1734.23,10.26
                c929.76-258.62,1961.31,53.82,1961.31,53.82"
              />
              <path
                id="flow_path_C"
                className="flow_main_path"
                fill="transparent"
                d="M0.67,985.8c1180.04,420.23,1613.19-235.7,3022.85,53.42c0,0,846.63,257.16,1734.23,10.26
                c929.76-258.62,1961.31,53.82,1961.31,53.82"
              />
            </svg>
          </div>
        </div>
        {/* <ReactHowler src={require("./audios/lyricslap.mp3")} playing={true} /> */}
      </div>
    </FullScreenVideoBgWrapper>
  );
};

Campfire.propTypes = {};

export default Campfire;
