import React, { useRef, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import sty from "./ModalPoetry.module.scss";

const ModalPoetry = ({ isModalOpen, text }) => {
  return (
    <>
      <div className={cx(sty.ModalPoetry, { active: isModalOpen })}>
        <div className="poem_content">
          <div className={sty.container}>{text}</div>
        </div>
      </div>
    </>
  );
};

ModalPoetry.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  text: PropTypes.string,
};

export default ModalPoetry;
