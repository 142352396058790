import { gsap } from "gsap";

export default function animateStartOffset() {
  // const flowContainer = document.querySelectorAll("flow_a");
  // console.log(flowContainer);
  // const period = [...flowContainer].length / 2;
  // console.log(period);
  const easeSettings = {
    ease: "none",
    duration: 50,
    repeat: -1,
  };
  const tweenA = gsap.to(".path_text_inner_A", {
    ...easeSettings,
    attr: { startOffset: `-=${250 * 8}px` },
  });
  const tweenB = gsap.to(".path_text_inner_B", {
    ...easeSettings,
    attr: { startOffset: `-=${250 * 8}px` },
  });
  const tweenC = gsap.to(".path_text_inner_C", {
    ...easeSettings,
    attr: { startOffset: `-=${250 * 8}px` },
  });
  return [tweenA, tweenB, tweenC];
}
