export default [
  {
    name: "方彥翔1",
    text: "14 DAYS CO-ISOLATION\n所有已開始的動作 只完成一半\n今日只發訊息 明日只接受\n重覆昨日說的話  即便對方已不在場",
  },
  {
    name: "方彥翔2",
    text: "一起從事同一件事   但永遠待在不同的房間\n約定一個孤獨時的去處 只能一人前往\n將所有接觸到的事物等分 重複使用 直到耗盡",
  },
  {
    name: "方彥翔3",
    text: "每天約好一起觸摸相同的材質  感覺他  向對方描述\n找出一種需要他人協助的身體姿態  鍛鍊自己單獨完成\n用離你最近的材料發明一只信物   每天結束前交給下一天的自己",
  },
  {
    name: "方彥翔4",
    text: "規劃一條不會遇到對方的路徑  彼此交換\n寫出你認為最孤獨的名字   放在一旁  想像今日陪伴著他\n將廚房整理成另一個人習慣的樣子   開始居家檢疫",
  },
  {
    name: "方彥翔5",
    text: "找到24個時區的友人   同時擁有一天\n完成他人進行一半的動作  然後抹除痕跡",
  },
  {
    name: "王榮文",
    text: "看書與智者對話\n行禪與自然融合\n遣孤獨不忘人間\n也無風雨也無晴",
  },
  {
    name: "張鐵志",
    text: "面對孤獨，\n你可以進入歷史的隧道，\n抵達世界的盡頭，\n穿越人性的良善與黑暗—— \n如果你用力閱讀。",
  },
  {
    name: "王增榮",
    // text: "想起我的恐龍，\n想起我的長毛象，\n想起很多的過往，\n現在只剩，在天空不發一語的\n月亮。\n\n（我是地球）",
    text: "門外總是喧鬧\n把窗也關牢，到中庭\n仰看幾萬光年以外的太陽\n然後，月光。",
  },
  {
    name: "李清志",
    text: "每個寫作的人，都是一座孤島",
  },
  {
    name: "林彥穎",
    text: "忘記在不斷失望中追求偶而的滿足",
  },
  {
    name: "林崇傑",
    text: "秋雨露寒 , 一個人走過熙熙攘攘",
  },
  {
    name: "孫啓榕",
    text: "由於輕描淡寫的坦白\n總是在眾聲喧嘩的放縱時刻\n才猛然憶起曾經歸屬於含笑凋零的妥協",
  },
  {
    name: "黃惠美",
    text: "細菌和孢子一同揚起\n地球嘆了一口氣",
  },
  {
    name: "涂平子",
    text: "睡夢中醒來\n發現排隊等待救援，焦慮的人群惶恐驚叫、奔逃四散。\n留下慘遭水火摧殘，泥濘、灰燼覆蓋的廢墟與大地。\n霎時感覺獨自一人的寧靜與安慰。",
  },
  {
    name: "林芳怡",
    // text: "起床、就寢。\n煮飯、吃飯、洗碗、好好繞圈走路，14天如一日的隔離日誌。\n夜夜空中思考，在繞圈中解放長久被綑綁的我。",
    text: "起床。吃飯。繞圈走路。就寢。\n14天如一日的隔離。\n在放空中思考，在繞圈中解放自己。"
  },
  {
    name: "陸穎魚",
    text: "夜深的臉／墜落窗邊／他在聽孤獨／親吻你／眼皮上的心事",
  },
  {
    name: "劉定騫",
    text: "「盼望春天於是冬眠，卻醒在萬籟俱寂的夜」 \n「你把自己鎖了起來，卻將鑰匙放在連自己都遺忘的地方」",
  },
  {
    name: "吳思瑤",
    text: "學貓吧～舔舐每一刻塵落星動，在孤獨中享受自由。",
  },
  {
    name: "楊淑媜",
    text: "從我心到你心，從月亮到光年外的星星。",
  },
  {
    name: "施岑宜",
    text: "孤獨是巨大的黑洞\n讓人心懷恐懼\n當有一天我們勇敢朝它而去\n才終於明暸\n原来那裏有光",
  },
  {
    name: "吳宜珮",
    text: "可以孤獨，就可以自由！\n靜默像休止符，成為下一個樂章開啟前，即將躍起的主旋律。",
  },
  {
    name: "連振佑",
    text: "明天 繼續用「歹鬥陣」的心情 去串聯所愛",
  },
  {
    name: "許棕宣",
    text: "很輕\n很輕\n輕巧的托起了整個江河\n\n很靜\n很靜\n靜謐的隱入溝縫 等待重生",
  },
  {
    name: "許悔之",
    text: "阿你看一隻白狐走過了雪地",
  }
];
