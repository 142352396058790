import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../components/LinkWithTransitionLock";

import FullScreenVideoBgWrapper from "./FullScreenVideoBgWrapper";

import Campfire from "../components/Curations/FANG_YAN_XIANG/Campfire/Campfire";

import { Expo, gsap } from "gsap";

class Page extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      // <Campfire />
      1
    );
  }
}

export default Page;
