import React, { useRef, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import ReactHowler from "react-howler";

const AudioPreloadList = ({ list }) => {
  return list.map(({ text, voiceFileUrl }, index) => (
    <ReactHowler
      key={`${text}${index}`}
      src={voiceFileUrl}
      playing={false}
      preload={true}
    />
  ));
};

AudioPreloadList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      voiceFileUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default AudioPreloadList;
